import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "../utils/url";

function formatDateToDMY(dateString) {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Months are 0-based, so we add 1
  const year = dateObject.getFullYear();
  return `${day}-${month}-${year}`;
}
export const blogsApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    createBlogs: builder.mutation({
      query: (formData) => ({
        url: config.api.endpoints.createBlogs,
        method: "POST",
        body: formData, // The data is already a FormData object containing the multipart data
        headers: {
          // 'Content-Type': 'multipart/form-data', // Set the Content-Type header directly here
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          console.log(`Upload progress: ${progress}%`);
        },
      }),

      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getAllBlogs"],
    }),
    getAllBlogs: builder.query({
      query: (query) =>
        config.api.endpoints.getAllBlogs(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store

        const newBlogs = [...response.blogs].map((b) => {
          return {
            ...b,
            author: `${b?.author?.firstName ?? ""} ${
              b?.author?.lastName ?? ""
            }`,
            date: b.published
              ? formatDateToDMY(b.publishedAt)
              : formatDateToDMY(b.createdAt),
          };
        });

        console.log("response => ", response, "newBlogs => ", newBlogs);

        return { ...response, blogs: newBlogs };
      },
      providesTags: ["getAllBlogs"],
    }),
    getBlogBySlug: builder.query({
      query: ({ slug }) => {
        if (!slug) {
          // Return a placeholder or empty data when slug is not available
          return Promise.resolve({
            data: { blog: null, isLoading: false, isSuccess: false },
          });
        }
        // Run the actual query when slug is available
        return config.api.endpoints.getSingleBlog(slug);
      },
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response => ", response);
        return response?.data?.blog;
      },
      providesTags: ["getBlogBySlug"],
    }),

    updateBlogs: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.updateBlogs(body.slug),
        method: "PUT",
        body: body.formData,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["getAllBlogs", "getBlogBySlug"],
    }),
    deleteBlog: builder.mutation({
      query: (slug) => ({
        url: config.api.endpoints.deleteBlog(slug),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store
        return response;
      },
      invalidatesTags: ["getAllBlogs"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useCreateBlogsMutation,
  useGetAllBlogsQuery,
  useUpdateBlogsMutation,
  useGetBlogBySlugQuery,
  useDeleteBlogMutation,
} = blogsApi;
