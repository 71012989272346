import React, { useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import useQueryParams from "../hooks/useQueryParams";
import {
  useCreateCryptoJobCountryMutation,
  useDeleteCryptoJobCountryMutation,
  useGetCountriesQuery,
  useUpdateCryptoJobCountryMutation,
} from "../services/cryptoCountry";
import { hasPermission } from "../utils/permissions";

const { Search } = Input;
const onSearch = (value) => console.log(value);
// Search End

const { Text, Link } = Typography;

const { TextArea } = Input;

const { confirm } = Modal;

function Addcryptocountry() {
  const [ShowUser, setShowUser] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const isTechnologyCreate = hasPermission("technologies:create");
  const isTechnologyEdit = hasPermission("technologies:edit");
  const isTechnologyDelete = hasPermission("technologies:delete");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    isLoading,
    data: cryptoJobCountry,
    isError,
    error,
  } = useGetCountriesQuery(
    {
      page,
      perPage: 10,
      filter: searchQuery,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );

  const [
    createCryptoJobCountry,
    {
      // isLoading: isCreateAdminLoading,
      // isSuccess: isCreateAdminSuccess,
      // isError: isCreateAdminError,
      // error: createAdminError,
      // data: createAdminData,
    },
  ] = useCreateCryptoJobCountryMutation();
  const [updateCryptoJobCountry] = useUpdateCryptoJobCountryMutation();
  const [deleteCryptoJobCountry] = useDeleteCryptoJobCountryMutation();

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      filter: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure delete this Country?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        deleteCryptoJobCountry(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const OpenUser = (id) => {
    setSelectedId(id);
    const existingCountry = cryptoJobCountry?.countries.find(
      (country) => country._id === id,
    );
    if (existingCountry) {
      // Populate form fields with existing city data
      form.setFieldsValue({
        code: existingCountry.code, // Assuming select_country is the name of the country field in the form
        name: existingCountry.name,
      });
    }

    setShowUser(true);
  };
  // const UserOk = async () => {
  //   try {
  //     const formData = await form.validateFields(); // Validate the form fields

  //     // Make API call
  //     setLoading(true);
  //     const response = await createCryptoJobCountry(formData);
  //     setLoading(false);

  //     console.log("Response from API:", response); // Log the response data

  //     // Check if the response indicates success
  //     if (response.error) {
  //       toast.error("Failed to add country. Please try again later.");
  //     } else {
  //       // Reset the form fields after successful submission
  //       form.resetFields();
  //       setShowUser(false);

  //       // Show success toast
  //       toast.success("Country added successfully");
  //     }
  //   } catch (error) {
  //     // Show error toast for form validation errors
  //     toast.error(
  //       "Form validation failed. Please check the fields and try again.",
  //     );
  //   }
  // };

  const UserOk = async (values) => {
    try {
      if (selectedId) {
        // Update country if selectedId exists
        const response = await updateCryptoJobCountry({
          id: selectedId,
          data: { code: values.code, name: values.name },
        });
        if (response.error) {
          console.error("Error updating country:", response.error);
          toast.error("Failed to update country. Please try again later.");
        } else {
          toast.success("Country updated successfully.");
          UserCancel(); // Close the modal after successful update
        }
      } else {
        // Create country if selectedId does not exist
        const formData = await form.validateFields();
        setLoading(true);
        const response = await createCryptoJobCountry(formData);
        setLoading(false);
        if (response.error) {
          console.error("Error creating country:", response.error);
          toast.error("Failed to create country. Please try again later.");
        } else {
          toast.success("Country created successfully.");
          UserCancel(); // Close the modal after successful creation
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  // const UserCancel = () => {
  //   form.resetFields(); // Reset the form fields when modal is cancelled
  //   // onCancel();
  // };
  const UserCancel = () => {
    form.resetFields(); // Reset the form fields when modal is cancelled

    setSelectedId(null);
    setShowUser(false);
  };
  // Create User Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  const onSearch = (value) => {
    setPage(1);
    setSearchQuery(value); // Update the 'search' state with the entered value
    // console.log(value);
    // Here you can use the 'value' to perform any search-related operations.
    // For example, you can use the 'value' to filter the table data based on the search query.
  };

  // Set up pagination options
  const paginationOptions = {
    total: cryptoJobCountry?.totalCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);

      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };
  // User Table Data Stat
  const columns = [
    {
      title: "Country Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  if (isTechnologyEdit || isTechnologyDelete) {
    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {isTechnologyEdit && (
            <a className="primary_color" onClick={() => OpenUser(record?._id)}>
              <EditOutlined />
            </a>
          )}
          {isTechnologyDelete && (
            <a
              className="danger_color"
              onClick={() => showDeleteConfirm(record?._id)}
              type="dashed"
            >
              <DeleteOutlined />
            </a>
          )}
        </Space>
      ),
    });
  }
  // Reset Form
  const [form] = Form.useForm();
  const [Cityform] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  const [componentDisabled, setComponentDisabled] = useState(false);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };
  const { Title, Text, Paragraph } = Typography;

  const [isCityModalOpen, setCityIsModalOpen] = useState(false);
  const showCityModal = () => {
    setCityIsModalOpen(true);
  };
  const handleCityOk = () => {
    setCityIsModalOpen(false);
  };
  const handleCityCancel = () => {
    setCityIsModalOpen(false);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div className="">
                      <Search
                        className="setInnerContent"
                        allowClear
                        placeholder="Input search text"
                        onSearch={onSearch}
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                      {/* {search && (
                        <button
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "228px",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            setSearch("");
                          }}
                        >
                          <FaTimes />
                        </button>
                      )} */}
                    </div>

                    {isTechnologyCreate && (
                      <div className="create_user_btn_div">
                        <Button
                          type="primary"
                          className="ms-3 px-3 rounded-5"
                          onClick={() => setShowUser(true)}
                        >
                          Add New Country
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading}
                      pagination={paginationOptions}
                      columns={columns}
                      dataSource={cryptoJobCountry?.countries}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <div>
              {/* Add country and city */}

              <Modal
                title={selectedId ? "Update Country" : "Add New Country"}
                centered
                width={500}
                visible={ShowUser}
                onCancel={UserCancel}
                footer={[
                  <Button key="back" onClick={UserCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="techForm"
                    loading={loading}
                  >
                    {selectedId ? "Update" : "Add"}
                  </Button>,
                ]}
              >
                <Form
                  id="techForm" // Add an id to the form
                  initialValues={{ name: "", code: "" }}
                  layout="vertical"
                  onValuesChange={onFormLayoutChange}
                  disabled={componentDisabled}
                  onFinish={UserOk}
                  form={form}
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Country Code"
                        name="code"
                        rules={[
                          {
                            required: true,
                            message: "Enter Country Code",
                          },
                        ]}
                      >
                        <Input placeholder="Country Code" className="mt-2" />
                      </Form.Item>
                      <Form.Item
                        label="Country Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Enter Country Name",
                          },
                        ]}
                      >
                        <Input placeholder="Country Name" className="mt-2" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Addcryptocountry;
