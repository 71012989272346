import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";

import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  useCreateBlogsMutation,
  useGetBlogBySlugQuery,
  useUpdateBlogsMutation,
} from "../services/blogs";
import { useGetCategoriesQuery } from "../services/category";
import { selectUser } from "../slices/user";
import { hasPermission } from "../utils/permissions";

// Define a custom validator function for the body field
const validateBody = (rule, value, callback) => {
  if (value && value.length >= 10) {
    callback(); // Validation passed
  } else {
    callback("Blog Description must be at least 10 characters long");
  }
};

// Define a custom validator function for the categories field
const validateCategories = (rule, value, callback) => {
  if (Array.isArray(value) && value.length > 0) {
    callback(); // Validation passed
  } else {
    callback("Please select at least one category");
  }
};

// Define a custom validator function for the categories field
const validateTags = (rule, value, callback) => {
  if (Array.isArray(value) && value.length >= 5) {
    callback(); // Validation passed
  } else {
    callback("Please select at least 5 tags");
  }
};

const Blogs = () => {
  const [value, setValue] = useState("");
  const user = useSelector(selectUser);
  const isManageCatCreate = hasPermission("manageCategories:create");
  const [images, setImages] = React.useState([]);
  const [isImageError, setIsImageError] = useState(false);
  let history = useHistory();
  const location = useLocation();

  const {
    data: blog,
    isLoading: singleblogLoading,
    isSuccess: singleblogSuccess,
  } = useGetBlogBySlugQuery({ slug: location.state?.slug });

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" && inputValue.trim() !== "") {
  //     setTags([...tags, inputValue]);
  //     setInputValue("");
  //   }
  // };
  const [
    createBlogs,
    {
      isLoading: blogsLoading,
      isSuccess: isBlogSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateBlogsMutation();
  const [
    updateBlogs,
    {
      isLoading: updateblogsLoading,
      isSuccess: isupdateBlogSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateBlogsMutation();

  useEffect(() => {
    if (isCreateError) {
      toast.error(createError?.data?.message || "Something went wrong");
    }
  }, [isCreateError]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateError?.data?.message || "Something went wrong");
    }
  }, [isUpdateError]);

  const {
    data: categories,
    isLoading,
    isError,
    erroor,
    isSuccess,
  } = useGetCategoriesQuery();
  // console.log(categories, "categorieslist");
  const initialValues = {
    body: "",
    title: "",
    slug: "",
    metaDescription: "",
    metaTitle: "",
    metaTags: "",
    categories: "",
  };
  const onFinish = (values) => {
    let image = images.length > 0 ? images[0].file : null;
    let imageUrl = images.length > 0 ? images[0].data_url : null;

    if (!image && !imageUrl) {
      return setIsImageError(true);
    }
    if (!image) {
      image = imageUrl;
    }
    let data = {
      ...values,
      metaTags: tags,
      media: image,
      categories: selectedCategories,
      author: user?._id,
    };

    // console.log("Blog Values", values);
    if (!values?.slug) {
      delete data.slug;
    }

    const formData = new FormData();

    // Iterate through the properties of the data object
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          // If the property is an array, iterate through its items and append each item with a unique name
          for (let i = 0; i < data[key].length; i++) {
            formData.append(`${key}[${i}]`, data[key][i]);
          }
        } else {
          // If the property is not an array, append it as is
          formData.append(key, data[key]);
        }
      }
    }

    if (location.state?.slug) {
      updateBlogs({ formData, slug: location.state.slug });
    } else {
      createBlogs(formData);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (isBlogSuccess || isupdateBlogSuccess) {
      // navigate("/listed_blogs")
      if (isBlogSuccess) history.push("/listed_blogs");
      else history.push("/listed_blogs" + location.state?.query);
    }
  }, [isBlogSuccess, isupdateBlogSuccess]);
  const [componentDisabled, setComponentDisabled] = useState(false);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };
  const [form] = Form.useForm();
  const [blogDescription, setBlogDescription] = useState(""); // State for Quill value

  const handleQuillChange = (value) => {
    setBlogDescription(value);
    form.setFieldsValue({ body: value }); // Update the form field value
  };
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newTag = inputValue.trim();
      if (newTag) {
        const tagIndex = tags.findIndex((tag) => tag === newTag);
        if (tagIndex !== -1) {
          // If the tag already exists, update it
          const updatedTags = [...tags];
          updatedTags[tagIndex] = newTag;
          setTags(updatedTags);
        } else {
          // If the tag doesn't exist, add it
          setTags([...tags, newTag]);
        }
        setInputValue(""); // Clear the input
      }
    }
  };

  const handleDeleteTag = (indexToDelete) => {
    const updatedTags = tags.filter((_, index) => index !== indexToDelete);
    setTags(updatedTags);
  };

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    const mediaValue = imageList.length > 0 ? imageList[0].data_url : "";
    setIsImageError(false);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategoryChange = (e, categoryId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedCategories([...selectedCategories, categoryId]);
      // setSelectedCategories([categoryId]);
    } else {
      // setSelectedCategories([]);

      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId),
      );
    }
  };

  useEffect(() => {
    if (singleblogSuccess) {
      setSelectedCategories(blog?.categories?.map((c) => c._id));
      setImages((pre) => {
        return [{ data_url: blog?.media }];
      });
      setBlogDescription(blog?.body);
      setTags(blog?.metaTags);
      const mockData = {
        title: blog?.title,
        metaDescription: blog?.metaDescription,
        metaTitle: blog?.metaTitle,
        categories: blog?.categories?.map((c) => c._id),
        body: blog?.body,
        metaTags: blog?.metaTags,
        slug: blog?.slug,
      };

      form.setFieldsValue(mockData);
    }
  }, [singleblogSuccess]);
  // console.log("blog & selectedCategories => ", { blog, selectedCategories });

  return (
    <>
      <Form
        form={form}
        name="complex-form"
        initialValues={initialValues}
        onValuesChange={onFormLayoutChange}
        disabled={componentDisabled}
        onFinish={onFinish}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div
                className="px-lg-4 px-md-4 py-lg-4 rounded-3"
                style={{
                  boxShadow:
                    "4.46667px 4.46667px 29.7778px rgba(2, 12, 65, 0.1)",
                }}
              >
                {/* <h2 className="pb-3 pt-lg-0 pt-md-3 pt-3">
                  {location.state?.slug ? "Update Blog" : "Add New Blog"}
                </h2> */}

                <Form.Item
                  label="Title:"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a title.",
                    },
                    {
                      min: 5,
                      message: "Title must be at least 5 characters long.",
                    },
                    {
                      max: 100,
                      message: "Title cannot exceed 100 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Add title Here" />
                </Form.Item>
                {location.state?.slug && (
                  <Form.Item
                    label="Slug:"
                    name="slug"
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9-]*$/,
                        message:
                          "Only alphanumeric characters and hyphens are allowed",
                      },
                    ]}
                  >
                    <Input placeholder="Add Slug Here" />
                  </Form.Item>
                )}

                <Form.Item
                  name="body"
                  label="Blog Description"
                  className="error_message_top_space pb-0 mb-0"
                  rules={[
                    {
                      validator: (rule, value, callback) =>
                        validateBody(rule, blogDescription, callback),
                    },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    value={blogDescription}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          // "blockquote"
                        ],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          // { indent: "-1" },
                          // { indent: "+1" },
                        ],
                        // [{ size: ["small", "large", "huge"] }],
                        [{ color: [] }], // Color icon
                        ["link", "image"],
                      ],
                    }}
                    onChange={handleQuillChange}
                    style={{ width: "100%", height: "350px" }}
                  />
                </Form.Item>

                <div className="d-flex justify-content-end mt-5 publish_btn_top">
                  <Button
                    loading={blogsLoading || updateblogsLoading}
                    type="primary"
                    disabled={blogsLoading || updateblogsLoading}
                    htmlType="submit"
                    className=" px-3"
                    style={{ fontSize: "0.9em", marginTop: "15px" }}
                  >
                    {location.state?.slug ? "Update" : "Publish"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div
                class="card border-0 px-2"
                style={{ boxShadow: "0 20px 27px rgb(0 0 0 / 5%)" }}
              >
                <div class="card-body" id="input_tags">
                  <div className="pt-1">
                    <Form.Item
                      label="Meta Title:"
                      name="metaTitle"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a meta title.",
                        },
                        {
                          max: 100,
                          message: "Meta Title cannot exceed 100 characters.",
                        },
                      ]}
                    >
                      <Input placeholder="Add Meta Title Here" />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      label="Meta Description:"
                      name="metaDescription"
                      rules={[
                        {
                          required: true,
                          message: "Please enter meta description.",
                        },
                        {
                          max: 250,
                          message:
                            "Meta Description cannot exceed 250 characters.",
                        },
                      ]}
                    >
                      <Input placeholder="Add Meta Description Here" />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="metaTags"
                    label="Metatags"
                    rules={[
                      {
                        validator: (rule, value, callback) =>
                          validateTags(rule, tags, callback),
                      },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        borderRadius: "7px",
                        padding: "0px 5px",
                      }}
                    >
                      {tags?.map((tag, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor: "#f0f0f0",
                            borderRadius: "3px",
                            padding: "3px 8px",
                            margin: "2px",
                            wordBreak: "break-word",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>{tag}</span>
                          <button
                            type="button"
                            onClick={() => handleDeleteTag(index)}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                            }}
                          >
                            X
                          </button>
                        </div>
                      ))}

                      <input
                        type="text"
                        placeholder="Type and press Enter to create a tag"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ flex: "1", border: "none", outline: "none" }}
                      />
                    </div>
                  </Form.Item>
                  <p className="separate_tags mb-2">Seperate Tags with Enter</p>
                  <ImageUploading
                    multiple={false}
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        {!images?.length && (
                          <button
                            // style={isDragging ? { color: 'red' } : undefined}
                            className="image_selct_box"
                            onClick={onImageUpload}
                            type="button"
                            {...dragProps}
                          >
                            Click to Add Image
                          </button>
                        )}
                        <div>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image_upload_box mb-2">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  onClick={() => onImageUpdate(index)}
                                />
                              </div>
                              <div className="image-item__btn-wrapper">
                                <button
                                  onClick={() => onImageUpdate(index)}
                                  type="button"
                                  className="btn update_img_btn"
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() => onImageRemove(index)}
                                  type="button"
                                  className="btn remove_img_btn"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  {isImageError && (
                    <p className="text-danger">Image is required</p>
                  )}

                  <Form.Item
                    name="categories"
                    label="Categories"
                    rules={[
                      {
                        validator: (rule, value, callback) =>
                          validateCategories(
                            rule,
                            selectedCategories,
                            callback,
                          ),
                      },
                    ]}
                  >
                    <div className="form-check" id="modal_token_checkbox">
                      {categories?.categories?.map((category) => (
                        <div key={category._id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`flexCheckChecked-${category._id}`}
                            value={category._id}
                            name="categoryGroup"
                            checked={selectedCategories.includes(category._id)}
                            onChange={(e) =>
                              handleCategoryChange(e, category._id)
                            }
                          />
                          <label
                            className="form-check-label ps-2"
                            htmlFor={`flexCheckChecked-${category._id}`}
                          >
                            {category.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Form.Item>

                  {isManageCatCreate && (
                    <div className="mt-5 mb-3">
                      <Link to="/category" className="image_selct_box">
                        Add New Category
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Blogs;
