import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useGetSubcategoriesQuery,
  useUpdateCategoryMutation,
} from "../services/category";
import { hasPermission } from "../utils/permissions";

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

const { confirm } = Modal;

const Subcategory = () => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setIsImageError(false);

    setImages(imageList);
  };
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isManageCatCreate = hasPermission("manageCategories:create");
  const isManageCatEdit = hasPermission("manageCategories:edit");
  const isManageCatDelete = hasPermission("manageCategories:delete");
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isImageError, setIsImageError] = useState(false);
  const [cat, setCat] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [selectedId, setSelectedId] = useState(null);
  const [isCatError, setIsCatError] = useState(false);
  const [parentCatId, setParentCatId] = useState(null);
  const showModal = (id) => {
    id && setSelectedId(id);
    setIsModalOpen(true);
  };

  const initialValues = {
    parentCategory: "", // Set the default value here
    name: "",
  };

  const handleOk = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  // Reset Form
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const { data: subcategories } = useGetSubcategoriesQuery({
    page,
    limit: 10,
    search: searchQuery,
  });

  const { data: categories, refetch: refetchAllCategories } =
    useGetCategoriesQuery({
      search: searchQuery,
    });

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Are you sure delete this subcategory?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        deleteCategory(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  // Set up pagination options
  const paginationOptions = {
    total: subcategories?.totalCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  const tableheadings = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
  ];

  if (isManageCatEdit || isManageCatDelete) {
    tableheadings.push({
      title: "Action",
      key: "action",
      render: (item, record) => (
        <Space size="middle">
          {isManageCatEdit && (
            <a className="primary_color" onClick={() => showModal(record?._id)}>
              <EditOutlined />
            </a>
          )}
          {isManageCatDelete && (
            <a
              className="danger_color"
              onClick={() => showDeleteConfirm(record?._id)}
              type="dashed"
            >
              <DeleteOutlined />
            </a>
          )}
        </Space>
      ),
    });
  }

  const [
    createCategory,
    {
      isLoading: categoryLoading,
      isSuccess: isCatSuccess,
      isError: isCatCreateError,
      error: catError,
    },
  ] = useCreateCategoryMutation();
  const [
    updateCategory,
    {
      isLoading: updateLoading,
      isSuccess: isCatUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateCategoryMutation();
  const [
    deleteCategory,
    {
      isLoading: categoryDeleteLoading,
      isSuccess: categoryDeleteSuccess,
      isError: isCategoryDeleteError,
      error: deleteError,
    },
  ] = useDeleteCategoryMutation();

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateError?.data?.message || "Something went wrong");
      setIsModalOpen(false);
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isCatCreateError) {
      toast.error(catError?.data?.message || "Something went wrong");
      setIsModalOpen(false);
    }
  }, [isCatCreateError]);

  useEffect(() => {
    if (isCategoryDeleteError) {
      toast.error(
        deleteError?.data?.message || "Category is not deleted successfully",
      );
    }
  }, [isCategoryDeleteError]);

  useEffect(() => {
    if (isCatSuccess) {
      toast.success("Subcategory is created successfully");
      setIsModalOpen(false);
      setSelectedId(null);
      setImages([]);
      onReset();
    }
  }, [isCatSuccess]);

  useEffect(() => {
    if (isCatUpdateSuccess) {
      toast.success("Subcategory is updated successfully");
      setIsModalOpen(false);
      setSelectedId(null);
      setImages([]);
      onReset();
    }
  }, [isCatUpdateSuccess]);

  useEffect(() => {
    if (categoryDeleteSuccess) {
      toast.success("Subcategory is deleted successfully");
      setSelectedId(null);
    }
  }, [categoryDeleteSuccess]);

  useEffect(() => {
    if (selectedId) {
      const category = subcategories?.subcategories?.find(
        (c) => c._id === selectedId,
      );

      const mockData = {
        name: category?.name,
        parentCategory: category?.parentCategory,
      };

      form.setFieldsValue(mockData);

      // setCat(category?.name);
      setImages((pre) => {
        return [{ data_url: category?.image }];
      });
    } else {
      onReset();
      setImages([]);
    }
  }, [selectedId]);

  const onSearch = (value) => {
    setSearchQuery(value); // Update the 'search' state with the entered value
    console.log(value);
    // Here you can use the 'value' to perform any search-related operations.
    // For example, you can use the 'value' to filter the table data based on the search query.
  };

  const onFinish = (values) => {
    // let isImageNot = images.length === 0;
    // if (isImageNot) {
    //   setIsImageError(true);
    //   return;
    // }

    // Create a FormData object
    const formData = new FormData();
    // formData.append("image", images[0].file); // Assuming 'file' is the file data from the images array
    formData.append("name", values.name);
    formData.append("parentCategory", values.parentCategory);

    if (selectedId) {
      updateCategory({ body: formData, id: selectedId });
    } else {
      createCategory(formData);
    }
  };

  const buttonText = () => {
    let text;
    if (selectedId) {
      text = !updateLoading ? "Update Subcategory" : "Updating...";
    } else {
      text = !categoryLoading ? "Create Subcategory" : "Creating..";
    }
    return text;
  };

  return (
    <div>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row
                gutter={[24, 0]}
                style={{ paddingLeft: "2em", paddingRight: "2em" }}
              >
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div className="">
                      <Search
                        style={{ maxWidth: "320px" }}
                        placeholder="Input search text"
                        allowClear
                        onSearch={onSearch}
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                    </div>
                    {isManageCatCreate && (
                      <div className="d-flex justify-content-end">
                        <div className="ms-auto">
                          <button
                            type="button"
                            onClick={() => showModal()}
                            className="add_button ms-3 mb-3 rounded-5"
                          >
                            Add a Sub category
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row
                gutter={[24, 0]}
                style={{ paddingLeft: "2em", paddingRight: "2em" }}
              >
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <Table
                    columns={tableheadings}
                    dataSource={subcategories?.subcategories || []}
                    pagination={paginationOptions} // Add the pagination options to the Table component
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <div>
        <Modal
          title={selectedId ? "Update Subcategory" : "Create Subcategory"}
          centered
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="container-fluid mt-3" id="forms_validation">
            <div className="row">
              <div className="col-12">
                <Form
                  initialValues={initialValues}
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <div className="">
                        <Row gutter={[24, 0]}>
                          <Col
                            xs={24}
                            md={24}
                            sm={24}
                            lg={24}
                            xl={24}
                            className="mobile-24"
                          >
                            <Form.Item
                              label="Select Category"
                              name="parentCategory"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select category",
                                },
                              ]}
                            >
                              <Select
                                value={parentCatId}
                                onChange={(value) => setParentCatId(value)}
                              >
                                <Select.Option value={""}>
                                  Select Category
                                </Select.Option>
                                {categories?.categories?.map((c) => {
                                  return (
                                    <Select.Option key={c._id} value={c._id}>
                                      {c.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            md={24}
                            sm={24}
                            lg={24}
                            xl={24}
                            className="mobile-24"
                          >
                            <Form.Item
                              label="Sub Category"
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a subcategory",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter a Subcategory"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col>
                            <div>
                              <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                              >
                                {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                                }) => (
                                  // write your building UI
                                  <div className="upload__image-wrapper">
                                    {!images?.length && (
                                      <button
                                        // style={isDragging ? { color: 'red' } : undefined}
                                        className="image_selct_box"
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Click to Add Image
                                      </button>
                                    )}
                                    <div>
                                      {imageList.map((image, index) => (
                                        <div key={index} className="image-item">
                                          <div className="image_upload_box">
                                            <img
                                              src={image["data_url"]}
                                              alt=""
                                              width="100"
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            />
                                          </div>
                                          <div className="image-item__btn-wrapper">
                                            <button
                                              type="button"
                                              className="btn update_img_btn"
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            >
                                              Update
                                            </button>
                                            <button
                                              type="button"
                                              className="btn remove_img_btn"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </ImageUploading>
                              {isImageError && (
                                <p className="text-danger">Image is required</p>
                              )}
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-start">
                    <button
                      type="submit"
                      className="btn create_category_btn mt-2 mb-3"
                      disabled={categoryLoading || updateLoading}
                    >
                      {buttonText()}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Subcategory;
