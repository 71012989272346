import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import { AudioOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Col, Form, Input, Modal, Row, Table, Typography } from "antd";

import useQueryParams from "../hooks/useQueryParams";
import {
  useGetSubscriberQuery,
  useLazyGetAllSubscriberCSVQuery,
} from "../services/subscriber";
// import UserModal from '../components/modal/modal';

// import card from "../assets/images/info-card-1.jpg";

// Search
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);
const onSearch = (value) => console.log(value);
// Search End

const { Text, Link } = Typography;

const { TextArea } = Input;

const { confirm } = Modal;
const showDeleteConfirm = () => {
  confirm({
    title: "Are you sure delete this subscriber?",
    icon: <ExclamationCircleFilled />,
    // content: 'Some descriptions',
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      console.log("OK");
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
let limit = 10;
function Subscribers() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const {
    isLoading: subsLoading,
    isFetching: subsFetching,
    data: subscribersData,
  } = useGetSubscriberQuery({
    page,
    limit,
    search,
  });

  const [
    trigger,
    { isLoading: subsCSVLoading, data: csvData, isSuccess: csvSuccess },
  ] = useLazyGetAllSubscriberCSVQuery();

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [search, setSearch, ""], // Update setSearch as well
    },
    watchList: [search, page],
  });

  // const [loading, setLoading] = useState(false);
  // User Show Modal
  const [ShowUser, setShowUser] = useState(false);
  const OpenUser = () => {
    setShowUser(true);
  };
  const UserOk = () => {
    setShowUser(false);
  };
  const UserCancel = () => {
    setShowUser(false);
  };
  // Create User Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (csvSuccess) {
      document.getElementById("csv-link").click();
    }
  }, [csvSuccess]);

  // User Table Data Stat
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  // User Table Data End

  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const { Title, Text, Paragraph } = Typography;

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);

  // Reset Form
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  // Define headers for the CSV file
  const headers = [
    { label: "Email", key: "email" },
    // Add more headers as needed
  ];

  // Set up pagination options
  const paginationOptions = {
    total: subscribersData?.totalSubscribers || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: limit, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className=" d-flex justify-content-end">
                    <div>
                      <Search
                        style={{ maxWidth: "320px" }}
                        placeholder="Input search text"
                        allowClear
                        onSearch={(value) => setSearch(value)}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        enterButton
                      />
                    </div>
                    <div>
                      <button
                        className="btn primary_orange_btn rounded-5 ms-3"
                        onClick={() => {
                          trigger();
                        }}
                        disabled={subsCSVLoading} // Disable the button while loading
                      >
                        Export to CSV
                      </button>

                      <CSVLink
                        id="csv-link"
                        filename={"Subscribers.csv"}
                        headers={headers}
                        data={csvData || []}
                        className="d-none"
                      >
                        Export to CSV
                      </CSVLink>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <Table
                    loading={subsLoading || subsFetching}
                    pagination={paginationOptions}
                    columns={columns}
                    dataSource={subscribersData?.subscribers}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Subscribers;
