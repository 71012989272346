import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Card, Col, Input, Modal, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useQueryParams from "../hooks/useQueryParams";
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from "../services/category";
import { hasPermission } from "../utils/permissions";
// Search
const { Search } = Input;

const { confirm } = Modal;

const Category = () => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const isManageCatCreate = hasPermission("manageCategories:create");
  const isManageCatEdit = hasPermission("manageCategories:edit");
  const isManageCatDelete = hasPermission("manageCategories:delete");
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [isImageError, setIsImageError] = useState(false);
  const [cat, setCat] = useState("");
  const [isCatError, setIsCatError] = useState(false);

  const { data: categories } = useGetCategoriesQuery(
    {
      page,
      limit: 10,
      search: searchQuery,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );

  const [
    createCategory,
    {
      isLoading: categoryLoading,
      isSuccess: isCatSuccess,
      isError: isCatCreateError,
      error: catError,
    },
  ] = useCreateCategoryMutation();

  const [
    updateCategory,
    {
      isLoading: updateLoading,
      isSuccess: isCatUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateCategoryMutation();
  const [
    deleteCategory,
    {
      isLoading: categoryDeleteLoading,
      isSuccess: categoryDeleteSuccess,
      isError: isCategoryDeleteError,
      error,
    },
  ] = useDeleteCategoryMutation();

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [searchQuery, page],
  });

  useEffect(() => {
    if (isCatSuccess) {
      toast.success("Category is created successfully");
      setIsModalOpen(false);
    }
  }, [isCatSuccess]);

  useEffect(() => {
    if (isCatCreateError) {
      toast.error(catError?.data?.message || "Something went wrong");
      setIsModalOpen(false);
    }
  }, [isCatCreateError]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateError?.data?.message || "Something went wrong");
      setIsModalOpen(false);
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isCatUpdateSuccess) {
      toast.success("Category is updated successfully");
      setIsModalOpen(false);
    }
  }, [isCatUpdateSuccess]);

  useEffect(() => {
    if (categoryDeleteSuccess) {
      toast.success("Category is deleted successfully");
    }
  }, [categoryDeleteSuccess]);

  useEffect(() => {
    if (isCategoryDeleteError) {
      toast.error(
        error?.data?.message || "Category is not deleted successfully",
      );
    }
  }, [isCategoryDeleteError]);

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Are you sure delete this category?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        deleteCategory(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const tableheadings = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
  ];

  if (isManageCatEdit || isManageCatDelete) {
    tableheadings.push({
      title: "Action",
      key: "action",
      render: (item, record) => (
        <Space size="middle">
          {isManageCatEdit && (
            <a className="primary_color" onClick={() => showModal(record?._id)}>
              <EditOutlined />
            </a>
          )}
          {isManageCatDelete && (
            <a
              className="danger_color"
              onClick={() => showDeleteConfirm(item._id)}
              type="dashed"
            >
              <DeleteOutlined />
            </a>
          )}
        </Space>
      ),
    });
  }

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);

    setImages(imageList);
    setIsImageError(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (id) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedId(null);
    setIsModalOpen(false);
  };
  // Set up pagination options
  const paginationOptions = {
    total: categories?.totalCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  const onCreateCategory = () => {
    // let isImageNot = images.length === 0;
    let isCatNot = cat.trim() === "";
    console.log({ images, cat, cat: cat.trim() === "" });
    // if (isImageNot) setIsImageError(true);
    if (isCatNot) setIsCatError(true);

    if (isCatNot) {
      return;
    }

    // Create a FormData object
    const formData = new FormData();
    // formData.append("image", images[0].file); // Assuming 'file' is the file data from the images array
    formData.append("name", cat.trim());

    // Call the createCategory mutation with the FormData object

    if (selectedId) {
      updateCategory({ body: formData, id: selectedId });
    } else {
      createCategory(formData);
    }
  };
  useEffect(() => {
    if (selectedId) {
      const category = categories?.categories?.find(
        (c) => c._id === selectedId,
      );
      setCat(category?.name);
      setImages((pre) => {
        return [{ data_url: category?.image }];
      });
    } else {
      setCat("");
      setImages([]);
    }
  }, [selectedId]);

  useEffect(() => {
    if (isCatSuccess || isCatUpdateSuccess) {
      handleOk();
    }
  }, [isCatUpdateSuccess, isCatSuccess]);

  const onSearch = (value) => {
    setSearchQuery(value); // Update the 'search' state with the entered value
    console.log(value);
  };
  const buttonText = () => {
    let text;
    if (selectedId) {
      text = !updateLoading ? "Update Category" : "Updating...";
    } else {
      text = !categoryLoading ? "Create Category" : "Creating..";
    }
    return text;
  };

  return (
    <div>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row>
                <div className="col-12">
                  {isManageCatCreate && (
                    <div className="d-flex justify-content-end me-4 mt-3">
                      <div>
                        <Search
                          style={{ maxWidth: "320px" }}
                          allowClear
                          placeholder="Input search text"
                          onSearch={onSearch}
                          enterButton
                          value={search}
                          onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => showModal()}
                          className="add_button rounded-5 ms-3"
                        >
                          Add Category
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
              <Row
                gutter={[24, 0]}
                style={{ paddingLeft: "2em", paddingRight: "2em" }}
              >
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <Table
                    className="table"
                    columns={tableheadings}
                    dataSource={categories?.categories || []}
                    pagination={paginationOptions} // Add the pagination options to the Table component
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <div>
        <Modal
          title={selectedId ? "Update Category" : "Create Category"}
          centered
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="container-fluid mt-3" id="forms_validation">
            <div className="row">
              <div className="col-12">
                <form className="signin_form">
                  <div className="input-block mb-20px">
                    <label htmlFor="category" className="form-label">
                      Category <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="category"
                      id="category"
                      className="form-control"
                      placeholder="Enter Category"
                      value={cat}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value.trim() !== "") {
                          setCat(value);
                          setIsCatError(false);
                        } else {
                          setCat(value);
                          setIsCatError(false);
                        }
                      }}
                    />
                    {isCatError && (
                      <p className="text-danger">Category cannot be empty</p>
                    )}
                  </div>
                </form>
                {/* <div>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        {!images?.length && (
                          <button
                            // style={isDragging ? { color: 'red' } : undefined}
                            className="image_selct_box"
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click to Add Image
                          </button>
                        )}
                        <div>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image_upload_box">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  onClick={() => onImageUpdate(index)}
                                />
                              </div>
                              <div className="image-item__btn-wrapper">
                                <button
                                  onClick={() => onImageUpdate(index)}
                                  className="btn update_img_btn"
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() => onImageRemove(index)}
                                  className="btn remove_img_btn"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  {isImageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                </div> */}
                <div className="text-start">
                  <button
                    type="submit"
                    className="btn create_category_btn mt-5 mb-3"
                    onClick={onCreateCategory}
                    disabled={categoryLoading || updateLoading}
                  >
                    {buttonText()}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Category;
