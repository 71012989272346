import React, { useEffect, useState } from "react";

import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import toast from "react-hot-toast";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import useQueryParams from "../hooks/useQueryParams";
import {
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useUpdateBlogsMutation,
} from "../services/blogs";
import { hasPermission } from "../utils/permissions";
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const makeFormData = (data) => {
  const formData = new FormData();
  formData.append("published", data.published);
};
const onSearch = (value) => console.log(value);
// Search End

const { Text, Link } = Typography;

const { TextArea } = Input;

const { confirm } = Modal;

function Listedblogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [isCryptUpdating, setIsCryptoUpdating] = useState(false);
  const isBlogEdit = hasPermission("blogs:edit");
  const isBlogDelete = hasPermission("blogs:delete");

  const location = useLocation();
  const history = useHistory();

  // Parse the URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const initialSearch = searchParams.get("search") || "";

  const [page, setPage] = useState(initialPage);
  const [search, setSearch] = useState(initialSearch);

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [page, search],
  });

  const {
    data: blogs,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllBlogsQuery({
    page,
    limit: 10,
    search: search,
  });

  const [
    updateBlogs,
    {
      isLoading: updateLoading,
      isSuccess: isBlogUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateBlogsMutation();

  const [loading, setLoading] = useState(false);

  const [
    deleteBlog,
    {
      isLoading: deleteBlogLoading,
      isSuccess: isDeleteBlogSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteBlogMutation();

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateError?.data?.message || "Something went wrong");
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteError?.data?.message || "Something went wrong");
    }
  }, [isDeleteError]);

  const showDeleteConfirm = (slug) => {
    confirm({
      title: "Are you sure delete this blog?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        deleteBlog(slug);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  // User Table Data Stat
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Author",
    //   dataIndex: "author",
    //   key: "author.firstName",
    // },

    {
      title: "Date Published",
      dataIndex: "date",
      key: "date",
    },
  ];

  if (isBlogEdit) {
    columns.push({
      title: "Published",
      key: "locked",
      render: (_, record) => (
        <Switch
          id={record.slug}
          checked={record.published}
          onChange={() => console.log("value changed")}
          onClick={async () => {
            try {
              setIsCryptoUpdating(false);
              setSelectedSlug(record.slug);
              const body = new FormData();
              body.append("published", !record.published);
              updateBlogs({ formData: body, slug: record.slug });
            } catch (error) {
              console.log("error => ", error);
            }
          }}
          loading={
            record.slug === selectedSlug && !isCryptUpdating
              ? updateLoading || isFetching
              : false
          }
        />
      ),
    });

    columns.push({
      title: "Crypto",
      key: "crypt",
      render: (_, record) => (
        <Switch
          id={record.slug}
          checked={record?.isCrypto ?? false}
          onChange={() => console.log("value changed")}
          onClick={async () => {
            try {
              setIsCryptoUpdating(true);
              setSelectedSlug(record.slug);
              const body = new FormData();
              body.append(
                "isCrypto",
                record?.isCrypto ? !record.isCrypto : true,
              );
              updateBlogs({ formData: body, slug: record.slug });
            } catch (error) {
              console.log("error => ", error);
            }
          }}
          loading={
            record.slug === selectedSlug && isCryptUpdating
              ? updateLoading || isFetching
              : false
          }
        />
      ),
    });
  }
  if (isBlogDelete || isBlogEdit) {
    columns.push({
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        return (
          <Space size="middle">
            <a
              className="danger_color"
              onClick={() => {
                history.push({
                  pathname: "/blog-preview",
                  state: { blogs, slug: record.slug, query: location.search },
                });
              }}
              type="dashed"
            >
              <EyeOutlined />
            </a>
            {isBlogEdit && (
              <a
                className="primary_color"
                onClick={() => {
                  history.push({
                    pathname: "/add_new_blog",
                    state: { slug: record.slug, query: location.search },
                  });
                }}
              >
                <EditOutlined />
              </a>
            )}
            {isBlogDelete && (
              <a
                className="danger_color"
                onClick={() => showDeleteConfirm(record.slug)}
                type="dashed"
              >
                <DeleteOutlined />
              </a>
            )}
          </Space>
        );
      },
    });
  }

  useEffect(() => {
    if (isDeleteBlogSuccess) {
      toast.success("Successfully deleted blog");
    }
  }, [isDeleteBlogSuccess, loading]);

  const [componentDisabled, setComponentDisabled] = useState(true);

  // Reset Form
  const [form] = Form.useForm();

  // Search End
  const paginationOptions = {
    total: blogs?.pagination?.totalResults || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col
                  xs={24}
                  md={24}
                  sm={24}
                  lg={12}
                  xl={18}
                  className="mb-24"
                ></Col>
                <Col xs={24} md={12} sm={24} lg={12} xl={6} className="mb-24">
                  <div className="">
                    <Search
                      placeholder="Input search text"
                      allowClear
                      onSearch={onSearch}
                      enterButton
                      value={search}
                      onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                    />
                  </div>
                </Col>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading}
                      columns={columns}
                      dataSource={blogs?.blogs}
                      pagination={paginationOptions}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Listedblogs;
