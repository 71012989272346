import React, { useEffect, useState } from "react";

import { AudioOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";

import { DatePicker } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import user_img from "../../src/assets/images/team-1.jpg";
import useQueryParams from "../hooks/useQueryParams";
import { useGetTopBuyersQuery } from "../services/user";

// import UserModal from '../components/modal/modal';

// import card from "../assets/images/info-card-1.jpg";

// Search
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

// const { Text, Link } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const { confirm } = Modal;
const showDeleteConfirm = () => {
  confirm({
    title: "Are you sure delete this user?",
    icon: <ExclamationCircleFilled />,
    // content: 'Some descriptions',
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      // console.log("OK");
    },
    onCancel() {
      // console.log("Cancel");
    },
  });
};

function TopBuyers() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [buyerinvestedsearch, setBuyerinvestedsearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [minEarning, setMinEarning] = useState("");
  const [maxEarning, setMaxEarning] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const {
    isLoading,
    data: topBuyers,
    refetch,
    isError,
    error,
  } = useGetTopBuyersQuery(
    {
      page,
      limit: 10,
      search: searchQuery,
      minSpending: minEarning,
      maxSpending: maxEarning,
      startDate: joiningDate,
      endDate: endDate,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );
  // console.log(topBuyers, "check buyer data");

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch],
      minearning: [minEarning, setMinEarning, ""],
      maxearning: [maxEarning, setMaxEarning, ""],
      joiningdate: [joiningDate, setJoiningDate, ""],
      enddate: [endDate, setEndDate, ""],
    },
    watchList: [
      searchQuery,
      page,
      joiningDate,
      endDate,
      maxEarning,
      minEarning,
    ],
  });

  const onSearch = (value) => {
    setSearchQuery(value);
    // console.log(value);
  };
  const onSearchbuyerinvested = (value) => {
    // console.log(value);
  };

  const paginationOptions = {
    total: topBuyers?.buyersCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      // console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  // const [loading, setLoading] = useState(false);
  // User Show Modal
  const [ShowUser, setShowUser] = useState(false);
  const OpenUser = () => {
    setShowUser(true);
  };
  const UserOk = () => {
    setShowUser(false);
  };
  const UserCancel = () => {
    setShowUser(false);
  };
  // Create User Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // User Table Data Stat
  const columns = [
    // {
    //     title: 'Name',
    //     dataIndex: 'name',
    //     key: 'name',
    //     render: (text) => <a href='/profile'>{text}</a>,
    // },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (username, record) => (
        // Step 2: Replace the anchor tag with Link component
        <Link
          to={`/profile/${username}`}
          style={{ fontWeight: "600", color: "black" }}
        >
          {username}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Type",
      key: "customer_type",
      dataIndex: "customer_type",
      render: (customer_type) => {
        const typeUpperCase = customer_type ? customer_type.toUpperCase() : "";

        return (
          <Tag color={typeUpperCase === "SELLER" ? "geekblue" : "green"}>
            {typeUpperCase}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        const statusUpperCase = status ? status.toUpperCase() : "";
        return (
          <Tag
            color={
              statusUpperCase === "COMPLETED"
                ? "geekblue"
                : statusUpperCase === "SUSPENDED"
                  ? "volcano"
                  : "green"
            }
          >
            {statusUpperCase}
          </Tag>
        );
      },
    },
  ];

  // User Table Data End

  const [componentDisabled, setComponentDisabled] = useState(true);
  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const { Title, Text, Paragraph } = Typography;

  // Upload Avatar
  // const uploadProps = {
  //   name: "file",
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  const handleMinEarningChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === "" || parseInt(value, 10) > 0)) {
      setMinEarning(value);
      if (value === "") {
        setMaxEarning("");
      }
    } else {
      // You can handle the invalid input here, for example, show an error message
      // console.error("Please enter a positive number greater than 0");
    }

    // setMinEarning(value);
  };

  const handleMaxEarningChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && (value === "" || parseInt(value, 10) > 0)) {
      setMaxEarning(value);
    } else {
      // You can handle the invalid input here, for example, show an error message
      // console.error("Please enter a positive number greater than 0");
    }

    // setMaxEarning(value);
  };

  // Reset Form
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  const onChangedatepicker = (date, dateString) => {
    // console.log(date, dateString);
    console.log("@765 joiningDate originally", date);
    if (!date) {
      setJoiningDate("");
      return;
    }

    const originalDate = new Date(date);

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${originalDate.getFullYear()}-${(
      originalDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;

    // console.log("Selected Date:", formattedDate);

    // Set the formatted date using setJoiningDate
    setJoiningDate(formattedDate);
  };

  const onChangeenddatepicker = (date, dateString) => {
    // console.log("@date Buyer End Date", {date, dateString});
    if (!date) {
      setEndDate("");
      return;
    }
    const originalDate = new Date(date);

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${originalDate.getFullYear()}-${(
      originalDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;

    setEndDate(formattedDate);
  };
  useEffect(() => {
    refetch();
  }, [endDate, joiningDate]);
  // Total Buyer Invested
  const [selectedValue, setSelectedValue] = useState(""); // Initially, no value is selected

  // Function to handle the change event
  const handleSelectChange = (value) => {
    setSelectedValue(value); // Update the selected value in the state
    // Add any additional logic you need here based on the selected value
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}></Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div className=" me-3">
                      <DatePicker
                        onChange={onChangedatepicker}
                        className="custom-datepicker"
                        placeholder="Joining Date"
                      />
                    </div>
                    <div className=" me-3">
                      <DatePicker
                        onChange={onChangeenddatepicker}
                        className="custom-datepicker"
                        placeholder="End Date"

                        // disabled={!joiningDate} // Disable if joiningDate is not selected
                      />
                    </div>

                    <div className=" me-3">
                      <Input
                        placeholder="Min Earning"
                        value={minEarning}
                        onChange={handleMinEarningChange}
                        style={{ maxWidth: "170px" }}
                        type="number"
                      />
                    </div>
                    <div className=" me-3">
                      <Input
                        placeholder="Max Earning"
                        value={maxEarning}
                        onChange={handleMaxEarningChange}
                        disabled={!minEarning}
                        type="number"
                        style={{ maxWidth: "170px" }}
                      />
                    </div>
                    <div className="">
                      <Search
                        placeholder="Input search text"
                        onSearch={onSearch}
                        allowClear
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={topBuyers?.paginatedBuyer || []}
                    pagination={paginationOptions} // Add the pagination options to the Table component
                  />
                </Col>
              </Row>
            </Card>

            <div>
              <Modal
                title="View User"
                centered
                width={800}
                visible={ShowUser}
                onOk={UserOk}
                onCancel={UserCancel}
                footer={[
                  <Button key="back" onClick={UserCancel}>
                    Return
                  </Button>,
                  <Button key="submit" type="primary" onClick={showModal}>
                    Edit
                  </Button>,
                ]}
              >
                <div>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mb-24"
                    >
                      <div className="d-flex justify-content-start">
                        <div className="mr-30">
                          <img
                            src={user_img}
                            alt=""
                            className="modal_user_img"
                          />
                        </div>
                        <div>
                          <Title className="mb-0">Dummy Name</Title>
                          <Paragraph
                            className="user_name_text"
                            copyable={{ text: "dummyuser.name" }}
                          >
                            @<span>dummyuser.name</span>
                          </Paragraph>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} className="pl-5 ml-5">
                    <Col xs={24} md={12} sm={24} lg={12} xl={12}>
                      <div>
                        <p className="user_view_modal_lable_heading">Email</p>
                        <p className="user_view_modal_data">
                          Dummy123@mail.com
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <p className="user_view_modal_lable_heading">Status</p>
                        <Tag color="green-inverse">Active</Tag>
                        {/* <p className='user_view_modal_data'>
                            Active
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TopBuyers;
