import React, { useState } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { Card, Col, Input, Row, Table } from "antd";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import useQueryParams from "../hooks/useQueryParams";
import { useGetApplicantsQuery } from "../services/applicants";
import { handleDownloadClick } from "../utils";
const { Search } = Input;

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

const onSearch = (value) => console.log(value);
// Search End

// const { Link } = Typography;

function Applicants() {
  const [searchQuery, setSearchQuery] = useState("");
  const { cryptoJobId } = useParams();
  console.log("cryptoJobId => ", cryptoJobId);

  const location = useLocation();

  console.log("location => ", location);

  // Parse the URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const initialSearch = searchParams.get("search") || "";

  const [page, setPage] = useState(initialPage);
  const [search, setSearch] = useState(initialSearch);
  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
    },
    watchList: [page, search],
  });

  const { data, isLoading } = useGetApplicantsQuery({
    page,
    limit: 10,
    search,
    cryptoJob: cryptoJobId,
  });

  // User Table Data Stat
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Resume",
      dataIndex: "resume",
      key: "resume",
      render: (resume, record) => {
        return (
          <span
            className="primary_color"
            onClick={() => {
              if (!resume) return;
              handleDownloadClick({
                url: resume,
                fileName: `${record?.firstName + "-" ?? ""}resume`,
              });
            }}
            style={{ fontWeight: "600", fontSize: "13px", cursor: "pointer" }}
          >
            {resume ? <DownloadOutlined /> : "N/A"}
          </span>
        );
      },
    },
  ];

  const paginationOptions = {
    total: data?.applicantsCount || 0,
    current: page,
    pageSize: 10,
    showSizeChanger: false,
    showQuickJumper: false,
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
    },
    onShowSizeChange: (current, size) => {},
  };

  const skillslist = [
    "JavaScript",
    "React",
    "Node.js",
    "HTML",
    "CSS",
    "Python",
    "Java",
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col
                  xs={24}
                  md={24}
                  sm={24}
                  lg={12}
                  xl={18}
                  className="mb-24"
                ></Col>
                <Col xs={24} md={12} sm={24} lg={12} xl={6} className="mb-24">
                  <div className="">
                    <Search
                      placeholder="Input search text"
                      onSearch={onSearch}
                      enterButton
                      value={search}
                      onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                    />
                  </div>
                </Col>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading}
                      columns={columns}
                      dataSource={data?.applicants || []}
                      pagination={paginationOptions}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Applicants;
