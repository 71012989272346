import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { BellOutlined } from "@ant-design/icons";
import { Card, Tabs, Typography } from "antd";
import { useSocket } from "../../hooks/socketContext";
import { useGetNotificationsQuery } from "../../services/dashboard";
import NotificationLayout from "./NotificationLayout";

const { Text } = Typography;
const { TabPane } = Tabs;

const Notification = () => {
  const { socket } = useSocket();
  const [notificationDataArray, setNotificationDataArray] = useState([]);

  const { isLoading, data, isError, error, refetch } = useGetNotificationsQuery(
    { recentCount: 5 },
  );

  const notifications = data?.mostRecent;
  // console.log("Notification=>", notifications);

  const getProposalInfo = () => {
    socket?.off("newJob").on("newJob", async (job) => {
      // console.log("job=>", job);
      refetch();
      toast.success("New Job is Created");
    });

    socket?.off("newUser").on("newUser", async (user) => {
      // console.log("newUser=>", user);
      refetch();
      toast.success("New User is Created");
    });

    socket?.off("newGig").on("newGig", async (gig) => {
      // console.log("newGig=>", gig);
      refetch();
      toast.success("New Gig is Created");
    });

    socket?.off("newOrder").on("newOrder", async (Order) => {
      // console.log("newOrder=>", Order);
      toast.success("New Order is Created");
    });
    socket?.off("newOrderCancel").on("newOrder", async (Order) => {
      // console.log("newOrderCancel=>", Order);
      toast.success("Order is Cancelled");
    });
  };
  getProposalInfo();

  return (
    <div>
      <li className="d-block nav-item dropdown list-unstyled padding_end_notifications display_none_icon text-end py-2">
        <Link
          to=""
          className="nav-link icon-link dropdown-toggle pt-1"
          data-bs-toggle="dropdown"
        >
          <BellOutlined />
          <div className="message_notification_dot"></div>
          {/* <BsBell className="fs-3 navbarEnvelope" fontWeight={900}/> */}

          {/* {isUnreadNotifications ? (
          <div className="message_notification_dot"></div>
        ) : null} */}
        </Link>
        <div
          className="dropdown-menu dropdown-menu-end notification_dropdown p-0 border-0"
          style={{ zIndex: "10000" }}
        >
          {notifications?.length == 0 ? (
            <>
              <div
                className="d-flex flex-column h-100 py-4"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="notifications_background_color">
                  <span className="notification_icon_border fs-3">
                    <i className="fa-solid fa-bell-slash"></i>
                  </span>
                </div>
                <Card
                  className="mb-1"
                  style={{ width: "100%" }}
                  title={<Text to={`/job_detail/`}>No Notifications</Text>}
                />
              </div>
            </>
          ) : (
            <div>
              <div className="container g-0">
                <div>
                  <div className="ps-3 message_notification_box_upper_border pt-3 pb-3 d-flex justify-content-between">
                    {/* <i className="fas fa-bell"></i> */}
                    <div>
                      <span className="mb-0 notification_text_dropdown">
                        Notifications
                      </span>
                    </div>
                    <div className="pe-3 notification_text_dropdown_num">
                      ({notifications?.length})
                    </div>
                  </div>
                </div>
                <NotificationLayout notifications={notifications} />
              </div>
              <div className="pe-3 pb-1 ">
                <div className="d-flex justify-content-end">
                  <Link
                    to="/notifications"
                    className="see_all_inbox pt-1"
                    style={{ textDecoration: "none" }}
                  >
                    See All Notifications
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </li>
    </div>
  );
};

export default Notification;
