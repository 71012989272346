import ApiBase from "../app/ApiBase";
import config from "../config/development";
import { objectToQueryString } from "./../utils/url";

export const dashboardApi = ApiBase.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => config.api.endpoints.getDashboardData,
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response: ", response);
        return response;
      },
      keepUnusedDataFor: 1000,
    }),

    getNotifications: builder.query({
      query: (query) =>
        config.api.endpoints.getNotifications(objectToQueryString(query)),
      transformResponse: (response) => {
        // handle successful response and return data to store
        console.log("response_notification: ", response);
        return response;
      },
      providesTags: ["getNotifications"],
    }),

    deleteNotifications: builder.mutation({
      query: (id) => ({
        url: config.api.endpoints.deleteNotifications(id),
        method: "DELETE",
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getNotifications"],
    }),

    readUnreadNotification: builder.mutation({
      query: (body) => ({
        url: config.api.endpoints.readUnreadNotification,
        method: "PATCH",
        body,
      }),
      transformResponse: (response) => {
        // handle successful response and return data to store

        console.log("response: ", response);
        return response;
      },
      invalidatesTags: ["getNotifications"],
    }),
  }),
});

// Generate hooks for each endpoint
export const {
  useGetDashboardQuery,
  useGetNotificationsQuery,
  useDeleteNotificationsMutation,
  useReadUnreadNotificationMutation,
} = dashboardApi;
