import { SolutionOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Import FiChevronUp
import { Link } from "react-router-dom";
import { hasPermission } from "../../utils/permissions";

const BlogsDropdown = ({ name, isCollapsed, toggleCollapse, closeSidebar }) => {
  const isBlogView = hasPermission("blogs:view");
  const isBlogCreate = hasPermission("blogs:create");
  const isBlogEdit = hasPermission("blogs:edit");
  const isBlogDelete = hasPermission("blogs:delete");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track dropdown open/closed

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    toggleCollapse(name);
  };
  const handleLinkClick = () => {
    if (closeSidebar) {
      closeSidebar();
    }
  };
  return (
    <div>
      <ul className="">
        <li>
          <a
            className="nav-link px-3 sidebar_link"
            href="#"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseExample_${name}`}
            aria-expanded={isCollapsed ? "false" : "true"}
            aria-controls={`collapseExample_${name}`}
            onClick={handleDropdownClick}
          >
            <span className="icon">
              <SolutionOutlined className="icon_bg" />
            </span>
            <span className="ms-2" style={{ fontSize: "0.85em" }}>
              Blogs
            </span>
            <span className="ps-1">
              {isCollapsed ? (
                <FiChevronDown className="ms-auto" />
              ) : (
                <FiChevronUp className="ms-auto" />
              )}
            </span>
            <span id="right-icon" className="ms-auto">
              <i className="bi bi-chevron-down"></i>
            </span>
          </a>

          <div
            className={`collapse ${isCollapsed ? "" : "show"}`}
            id={`collapseExample_${name}`}
          >
            <ul className="navbar-nav ps-4 ms-1">
              {isBlogCreate && (
                <li className="p-2" onClick={handleLinkClick}>
                  <Link
                    to="/add_new_blog"
                    className="nav-link px-3"
                    style={{ fontSize: "0.85em" }}
                  >
                    Add New Blog
                  </Link>
                </li>
              )}
              <li className="p-2" onClick={handleLinkClick}>
                <Link
                  to="/listed_blogs"
                  className="nav-link px-3"
                  style={{ fontSize: "0.85em" }}
                >
                  Listed Blogs
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default BlogsDropdown;
