import React, { useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect } from "react";
import toast from "react-hot-toast";
import {
  useCreateCryptoTagMutation,
  useDeleteCryptoTagMutation,
  useGetCryptoTagByIdQuery,
  useGetCryptoTagsQuery,
  useUpdateCryptoTagMutation,
} from "../services/cryptoTag";
import { hasPermission } from "../utils/permissions";
const { Search } = Input;
// Search End

const { Text, Link } = Typography;

const { TextArea } = Input;

const { confirm } = Modal;

function Cryptotags() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const isCryptoCreate = hasPermission("crypto:create");
  const isCryptoEdit = hasPermission("crypto:edit");
  const isCryptoDelete = hasPermission("crypto:delete");

  const [
    createTag,
    {
      isLoading: createTagLoading,
      error: createError,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateCryptoTagMutation();
  const [
    updateTag,
    {
      isLoading: updateTagLoading,
      error: updateError,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateCryptoTagMutation();
  const [
    deleteTag,
    {
      isLoading: deleteTagLoading,
      error: deleteError,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteCryptoTagMutation();
  const { isLoading, data: cryptoTags } = useGetCryptoTagsQuery({
    page,
    limit: 10,
    name: searchQuery,
  });

  const { isLoading: isTagByIdLoading, data: tagByIdData } =
    useGetCryptoTagByIdQuery(selectedId);

  useEffect(() => {
    if (createTagLoading) {
      toast.loading("Tag is Creating...", { id: 1 });
    } else if (isCreateSuccess) {
      toast.remove(1);
      toast.success("Tag is Created Successfully!");
    } else if (isCreateError) {
      toast.remove(1);
      toast.error(
        createError?.data?.message ||
          "Something went wrong while creating tag!",
      );
    }
  }, [createTagLoading, isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (updateTagLoading) {
      toast.loading("Tag is Update...", { id: 1 });
    } else if (isUpdateSuccess) {
      toast.remove(1);
      toast.success("Tag is Update Successfully!");
    } else if (isUpdateError) {
      toast.remove(1);
      toast.error(
        updateError?.data?.message ||
          "Something went wrong while updating tag!",
      );
    }
  }, [updateTagLoading, isUpdateSuccess, isUpdateError]);

  useEffect(() => {
    if (deleteTagLoading) {
      toast.loading("Tag is Deleting...", { id: 1 });
    } else if (isDeleteSuccess) {
      toast.remove(1);
      toast.success("Tag is Deleted Successfully!");
    } else if (isDeleteError) {
      toast.remove(1);
      toast.error(
        deleteError?.data?.message ||
          "Something went wrong while deleting tag!",
      );
    }
  }, [deleteTagLoading, isDeleteSuccess, isDeleteError]);
  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure delete this Crypto Tag?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteTag(id);
        // console.log("OK");
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };
  const OpenUser = (id) => {
    setSelectedId(id);
    setCityIsModalOpen(true);
  };

  const onSearch = (value) => {
    setSearchQuery(value); // Update the 'search' state with the entered value
    // console.log(value);
    // Here you can use the 'value' to perform any search-related operations.
    // For example, you can use the 'value' to filter the table data based on the search query.
  };

  // Set up pagination options
  const paginationOptions = {
    total: cryptoTags?.totalCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      // console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };
  // User Table Data Stat
  const columns = [
    {
      title: "Tag",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  if (isCryptoEdit || isCryptoDelete) {
    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {isCryptoEdit && (
            <a className="primary_color" onClick={() => OpenUser(record?._id)}>
              <EditOutlined />
            </a>
          )}
          {isCryptoDelete && (
            <a
              className="danger_color"
              onClick={() => showDeleteConfirm(record?._id)}
              type="dashed"
            >
              <DeleteOutlined />
            </a>
          )}
        </Space>
      ),
    });
  }
  // Reset Form
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedId && tagByIdData) {
      form.setFieldValue("name", tagByIdData?.name);
      form.setFieldValue("title", tagByIdData?.title);
      form.setFieldValue("description", tagByIdData?.description);
    } else if (!selectedId) {
      form.resetFields();
    }
  }, [selectedId, JSON.stringify(tagByIdData)]);

  const [isCityModalOpen, setCityIsModalOpen] = useState(false); // Assuming you have state for modal
  const showCityModal = () => {
    setCityIsModalOpen(true);
    setSelectedId(null);
  };

  const handleCityOk = () => {
    setCityIsModalOpen(false);
    setSelectedId(null);
  };

  const handleCityCancel = () => {
    setCityIsModalOpen(false);
    setSelectedId(null);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        // Values contain the validated fields (name, title, description)
        console.log("@123 Received values:", values);
        // Add your logic to handle form submission here

        if (selectedId) {
          await updateTag({ tagId: selectedId, tagData: values });
        } else {
          await createTag(values).catch((err) => {
            console.log("@123 error => ", err);
          });
        }

        setCityIsModalOpen(false);
        setSelectedId(null);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.error("@123 Validation failed:", errorInfo);
      });
  };

  const getCreateTagFormText = () => {
    if (selectedId) {
      return {
        formTitle: "Update Crypto Tag",
        submitButton: updateTagLoading ? "Updating..." : "Update",
      };
    } else {
      return {
        formTitle: "Add Crypto Tag",
        submitButton: createTagLoading ? "Creating..." : "Create",
      };
    }
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Search
                        placeholder="Input search text"
                        allowClear
                        onSearch={onSearch}
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                    </div>
                    {isCryptoCreate && (
                      <div className="create_user_btn_div">
                        <Button
                          type="primary"
                          className="ms-3 px-3 rounded-5"
                          onClick={showCityModal}
                        >
                          Add Crypto Tag
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="" id="crypto_tag_table">
                    <Table
                      loading={isLoading}
                      pagination={paginationOptions}
                      columns={columns}
                      dataSource={cryptoTags?.tags}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <div>
              <Modal
                title={getCreateTagFormText().formTitle}
                visible={isCityModalOpen}
                onOk={handleCityOk}
                onCancel={handleCityCancel}
                footer={null}
              >
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          { required: true, message: "Please input tag name!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Please input the tag title!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mobile-24"
                    >
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input the tag description!",
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        key="back"
                        onClick={handleCityCancel}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button key="submit" type="primary" htmlType="submit">
                        {getCreateTagFormText().submitButton}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Cryptotags;
