import { AudioOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Select as AntSelect,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import user_img from "../../src/assets/images/team-1.jpg";
import useQueryParams from "../hooks/useQueryParams";
import {
  useGetUsersQuery,
  useMakeUserRealOrDummyMutation,
} from "../services/user";

// Search
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 12,
      color: "#1890ff",
    }}
  />
);
// Search End

const { confirm } = Modal;
const showDeleteConfirm = () => {
  confirm({
    title: "Are you sure delete this user?",
    icon: <ExclamationCircleFilled />,
    // content: 'Some descriptions',
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      console.log("OK");
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};

function Users_Index() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [selectKey, setSelectKey] = useState(Date.now()); // Key for re-render
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [makeRealOrDummy, { isSuccess: realSuccess, isLoading: realLoading }] =
    useMakeUserRealOrDummyMutation();

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=name",
      // "https://restcountries.com/v3.1/all?fields=name,flags,code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        // setSelectedCountry(data.userSelectValue);
      });
  }, []);

  useQueryParams({
    params: {
      page: [page > 1 ? page : null, setPage, 1],
      search: [searchQuery, setSearchQuery, "", true, setSearch], // Update setSearch as well
      customer: [customerType, setCustomerType, ""],
      country: [
        selectedCountry && Object.keys(selectedCountry).length > 0
          ? selectedCountry
          : null,
        setSelectedCountry,
        {},
      ],
    },
    watchList: [searchQuery, customerType, page, selectedCountry],
  });

  const {
    isLoading,
    isFetching,
    data: users,
    isError,
    error,
  } = useGetUsersQuery(
    {
      page,
      perPage: 10,
      search: searchQuery,
      customerType: customerType.toLowerCase(),
      country:
        Object.keys(selectedCountry ?? {}).length > 0
          ? selectedCountry.value
          : "",
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        // clear data if error exists
        data: error ? [] : data,
        isLoading,
        error,
      }),
    },
  );
  const [ShowUser, setShowUser] = useState(false);

  useEffect(() => {
    // Update the key for the Select component after the initial mount
    setSelectKey(Date.now());
  }, [customerType]);

  const OpenUser = () => {
    setShowUser(true);
  };
  const UserOk = () => {
    setShowUser(false);
  };

  // useEffect(() => {
  //   if (isError) {
  //     console.log("Error: ", error);
  //     // Clear the cache
  //     useGetUsersQuery.clearCache();
  //   }
  // }, [isError])

  const onSearch = (value) => {
    setPage(1);
    setSearchQuery(value); // Update the 'search' state with the entered value
    console.log(value);
    // Here you can use the 'value' to perform any search-related operations.
    // For example, you can use the 'value' to filter the table data based on the search query.
  };
  const UserCancel = () => {
    setShowUser(false);
  };
  // Set up pagination options
  const paginationOptions = {
    total: users?.userCount || 0, // Set the total number of items
    current: page, // Set the current page number
    pageSize: 10, // Set the number of items per page
    // pageSizeOptions: ['5', '10', '20'], // Optional: Available page size options
    showSizeChanger: false, // Optional: Show the page size changer
    showQuickJumper: false, // Optional: Show quick jump to page input
    onChange: (page, pageSize) => {
      setPage(page);
      console.log("page => ", page);
      // You can implement your custom logic here to fetch data for the selected page
      // For example, you can use the useGetUsersQuery hook with the new page number
    },
    onShowSizeChange: (current, size) => {
      // You can implement your custom logic here to fetch data with the new page size
      // For example, you can use the useGetUsersQuery hook with the new page size
    },
  };

  const handleDummyOrReal = async (record) => {
    try {
      toast.loading(`Marking user as ${record?.isDummy ? "Real" : "Dummy"}`, {
        id: 1,
      });
      const payload = {
        id: record?._id,
        isDummy: record?.isDummy ? false : true,
      };

      const response = await makeRealOrDummy(payload);
      const errorMessage = response?.error?.data?.message;
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      toast.remove(1);
      toast.success(
        `Successfully marked user as ${record?.isDummy ? "Real" : "Dummy"}`,
      );
    } catch (error) {
      console.log("error => ", error);
      toast.remove(1);
      toast.error(
        `Something went wrong marking user as ${
          record?.isDummy ? "Real" : "Dummy"
        }`,
      );
    }
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (username, record) => (
        // Step 2: Replace the anchor tag with Link component
        <Link
          to={`/profile/${username}`}
          style={{ fontWeight: "600", color: "#000", fontSize: "13px" }}
        >
          {username}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "customer_type",
      render: (customer_type) => {
        const typeUpperCase = customer_type ? customer_type.toUpperCase() : "";
        return (
          <Tag color={typeUpperCase === "SELLER" ? "geekblue" : "green"}>
            {typeUpperCase}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        const statusUpperCase = status ? status.toUpperCase() : "";
        return (
          <Tag
            color={
              statusUpperCase === "COMPLETED"
                ? "geekblue"
                : statusUpperCase === "SUSPENDED"
                  ? "volcano"
                  : "green"
            }
          >
            {statusUpperCase}
          </Tag>
        );
      },
    },
    {
      title: "Authenticity",
      dataIndex: "View Chat1",
      key: "View Chat1",
      render: (chat, record) => {
        return (
          // Step 2: Replace he anchor tag witth Link component
          <>
            <button
              disabled={realLoading}
              onClick={() => handleDummyOrReal(record)}
              className={`${
                record?.isDummy ? "select_status_real" : "select_status_dummy"
              }`}
            >
              {record?.isDummy ? "Mark Real" : "Mark Dummy"}
            </button>
          </>
        );
      },
    },
    {
      title: "Messages",
      dataIndex: "View Chat",
      key: "View Chat",
      render: (chat, record) => {
        return (
          // Step 2: Replace he anchor tag witth Link component
          <>
            <Link
              to={`/personal_chat/${record?._id}`}
              className="select_status"
            >
              {" "}
              View Chat{" "}
            </Link>
            {/* <Link to={`/profile/${username}`}>{username}</Link> */}
          </>
        );
      },
    },
  ];

  // User Table Data End

  const { Title, Text, Paragraph } = Typography;

  // Reset Form
  const [form] = Form.useForm();
  const values = form.getFieldsValue();

  const countryProps = {
    placeholder: "Select Country",
    className: "select_react_country",
    options: countries,
    // value: selectedCountry,
    isClearable: !!selectedCountry?.value,
    onChange: (selectedOption) => {
      setSelectedCountry(selectedOption);
    },
  };

  if (Object.keys(selectedCountry ?? {}).length > 0) {
    countryProps["value"] = selectedCountry;
  }

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox mb-5">
              <Row gutter={[24, 0]}>
                <Col span={24} md={24} lg={24}>
                  <div className="d-flex justify-content-end ps-3">
                    <div className="">
                      <div className="me-lg-3 me-4" id="select_user_type">
                        <Form.Item
                          className="mb-3 antselect-type"
                          name="User type"
                        >
                          <AntSelect
                            key={selectKey}
                            defaultValue={customerType}
                            style={{
                              maxWidth: "200px",
                              minWidth: "200px",
                              fontSize: "1em",
                            }}
                            value={customerType}
                            onChange={(value) => {
                              setPage(1);
                              setCustomerType(value);
                            }}
                          >
                            <AntSelect.Option
                              value=""
                              className="all_text_fontsize"
                            >
                              Select User Type
                            </AntSelect.Option>
                            <AntSelect.Option
                              value="Seller"
                              className="all_text_fontsize"
                            >
                              Seller
                            </AntSelect.Option>
                            <AntSelect.Option
                              value="Buyer"
                              className="all_text_fontsize"
                            >
                              Buyer
                            </AntSelect.Option>
                          </AntSelect>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="me-3 position-relative">
                      <Select {...countryProps} />
                    </div>
                    <div className="">
                      <Search
                        placeholder="Input search text"
                        allowClear
                        className="all_text_fontsize"
                        onSearch={onSearch}
                        enterButton
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} // Update the 'search' state while typing
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <div className="">
                    <Table
                      loading={isLoading || isFetching}
                      className="table"
                      columns={columns}
                      dataSource={users?.users}
                      pagination={paginationOptions} // Add the pagination options to the Table component
                    />
                  </div>
                </Col>
              </Row>
            </Card>

            <div>
              <Modal
                title="View User"
                centered
                width={800}
                visible={ShowUser}
                onOk={UserOk}
                onCancel={UserCancel}
                footer={[
                  <Button key="back" onClick={UserCancel}>
                    Cancel
                  </Button>,
                  <a
                    type="button"
                    className="ant-btn ant-btn-primary"
                    href="/profile"
                  >
                    Edit
                  </a>,
                ]}
              >
                <div>
                  <Row gutter={[24, 0]}>
                    <Col
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={24}
                      className="mb-24"
                    >
                      <div className="d-flex justify-content-start">
                        <div className="mr-30">
                          <img
                            src={user_img}
                            alt=""
                            className="modal_user_img"
                          />
                        </div>
                        <div>
                          <Title className="mb-0">Dummy Name</Title>
                          <Paragraph
                            className="user_name_text"
                            copyable={{ text: "dummyuser.name" }}
                          >
                            @<span>dummyuser.name</span>
                          </Paragraph>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} className="pl-5 ml-5">
                    <Col xs={24} md={12} sm={24} lg={12} xl={12}>
                      <div>
                        <p className="user_view_modal_lable_heading">Email</p>
                        <p className="user_view_modal_data">
                          Dummy123@mail.com
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <p className="user_view_modal_lable_heading">Status</p>
                        <Tag color="green-inverse">Active</Tag>
                        {/* <p className='user_view_modal_data'>
                            Active
                        </p> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
            {/* User View Modal End */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Users_Index;
